/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { Link } from "gatsby"

const styles = css`
min-width: 120px;
text-align: center;
background: linear-gradient(to right, #fff 50%, #3b3836 50%);
transition:all 0.5s ease;
background-size: 205% 100%;
background-position:right bottom;
padding: 0.5rem 1rem;
text-decoration: none;
color: #fff;
border: 1px solid #3b3836;
&:hover {
  background-position:left bottom;
  transition:all 0.5s ease;
  color: #3b3836;
}
`

const Button = ({to, external, children}) => {
  if(external) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" css={styles}>
        {children}
      </a>
    )
  } else {
    return (
      <Link to={to} css={styles}>
        {children}
      </Link>
    )
  }
}

export default Button