/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children, bgColor, headerColor, hideHeader, unsetWidth, align }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      sitePlugin(name: {eq: "gatsby-plugin-manifest"}) {
        pluginOptions {
          background_color
        }
      }
    }
  `)

  return (
    <div style={{backgroundColor:bgColor ? bgColor : 'white'}}>
      {!hideHeader &&
        <Header siteTitle={data.site.siteMetadata.title} background={headerColor ? headerColor : data.sitePlugin.pluginOptions.background_color} />
      }
      <div
        style={{
          margin: `0 auto`,
          minWidth: 250,
          maxWidth: unsetWidth ? '100%' : 960,
          padding: unsetWidth ? 0 : `0 1.0875rem 1.45rem`,
        }}
      >
        <main
          style={{
            minHeight: `85vh`,
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: align ? align : 'center',
          }}
        >
          {children}
        </main>
        <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
